import { ArchiveIcon } from "@icons/ActionsIcons";
import {
    HomeIcon, UsersIcon, ResumesIcon, JobDescriptionsIcon, RankAndAnalyzeIcon, FAQsIcon
} from "@icons/DashboardIcons";
import { Home } from "@mui/icons-material";
import { Icon, ListItemIcon } from "@mui/material";

const MENU_ITEMS = [
    {
        title : "Home",
        icon : <HomeIcon className="dashboard-icon" />,
        segment : '',
        id : "homePageLink"
    },
    {
        title : "Users",
        icon : <UsersIcon className="dashboard-icon" />,
        segment : 'users',
        autorization : true,
        id : "usersPageLink"
    },
    {
        title : "Resumes",
        icon : <ResumesIcon className="dashboard-icon" />,
        segment: 'resumes',
        id : "resumesPageLink"
    },
    {
        title : "Job Descriptions",
        icon : <JobDescriptionsIcon className="dashboard-icon" />,
        segment : 'job-descriptions',
        id : "jobDescriptionsPageLink",
        pattern : "job-descriptions{/:jobId}*"
    },
    {
        title : "Rank & Analyze",
        icon : <RankAndAnalyzeIcon className="dashboard-icon" />,
        segment : 'rank-and-analyze',
        id : "rankAndAnalyzePageLink"
    },
    {
        title : "Help & Support",
        icon : <FAQsIcon className="dashboard-icon" />,
        segment : 'help-and-support',
        id : "helpAndSupportLink"
    },
    {
        title : "Archive",
        icon : <ArchiveIcon className="dashboard-icon" />,
        segment : 'archive',
        id : "archiveLink"
    }
]

export default MENU_ITEMS; 