import useResponsiveView from "@hooks/useResponsiveView";
import { Paper } from "@mui/material";

const ResponsiveWrapper = ({children}) => {
    const isMobile = useResponsiveView();

    return (
        isMobile ?
        <>{children}</>
        :
        <Paper
            elevation={1}
            sx={{
                width:"100%",
                padding:"0rem 1rem",
                height:"100%",
                borderRadius:"18px",
                display:"flex",
                flex:1,
                flexDirection: "column"
            }}
        >
            {children}
        </Paper>
    )
}

export default ResponsiveWrapper;