export const pageErrors = {
    PAGE_NOT_FOUND : {
        errorCode : 404,
        errorTitle : "Page Not Found",
        errorDescription : "We couldn't find the page you're looking for. This might be due to an outdated link or a mistyped URL.",
        suggestion : "Please check the URL or return to the homepage.",
        homeButton : "Return to Homepage"
    },
    FORBIDDEN : {
        errorCode : 403,
        errorTitle : "Forbidden",
        errorDescription : "Access denied",
        suggestion : "Please check the URL or return to the homepage.",
        homeButton : "Return to Homepage"
    },
    SYSTEM : {
        errorCode : "oops!",
        errorTitle : "Something Went Wrong",
        suggestion : "Please refresh the page or return to the homepage.",
        homeButton : "Return to Homepage"
    },
    RESOURCE_NOT_FOUND : {
        errorCode : 404,
        errorTitle : "Resource not found",
        suggestion : "Either resource was deleted or moved. please check the url again if entered manually.",
        homeButton : "Return to Homepage"
    },
    JOB_NOT_FOUND : {
        errorCode : 404,
        errorTitle : "Job Information not found",
        suggestion : "Either Job was closed or removed. please check the url again if entered manually.",
        // homeButton : "Return to Homepage"
    },
    INVALID_OR_EXPIRED_TOKEN : {
        errorCode : 401,
        errorTitle : "Invalid or Expired Link",
        suggestion : "Either link is expired, already used or invalid. please check the url again if entered manually.",
        homeButton : "Return to Login Page",
        target : "/auth/login"
    },
}