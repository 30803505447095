import "./rank-and-analyze.css";
import { useCallback, useEffect, useState } from "react";
import { IconButton, FormControlLabel, Radio, RadioGroup, Fade, Button, Paper } from "@mui/material";

import useResponsiveView from "@hooks/useResponsiveView";
import { AutorenewOutlined } from "@mui/icons-material";
import { CustomAutoComplete } from "@components/CustomMUIInputs";
import { FilenameRenderer, PreviewRenderer, StatusRenderer } from "@renderers/documents-renderers";
import { AnalyzeRenderer, ScoreRenderer } from "@renderers/rank-and-analyze-renderers";
import { CustomGrid, DocumentPreview } from "@components/index";
import { useDocuments } from "@contexts/DocumentsContext";
import { getSimilarityScore } from "@services/documentsService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";

const RankAndAnalyze = () => {
    const { documents, isLoading:isDocumentsLoading, isStale, updateDocumentStatus, syncDocuments } = useDocuments();
    const navigate = useNavigate();
    const location = useLocation();

    const [rowData, setRowData] = useState([]);
    const [filter, setFilter] = useState(()=>{
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get("filter");
        if(filterParam && ['resumes', 'job-descriptions'].includes(filterParam)){
            return filterParam;
        }
        return 'job-descriptions'
    });
    const [selectedDocument, setSelectedDocument] = useState("");
    const [previewDocument, setPreviewDocument] = useState(null);
    const [openPreview, setOpenPreview] = useState(false);
    
    const isMobile = useResponsiveView();

    useEffect(()=>{
        if(isDocumentsLoading) return;
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get("filter");
        if(filterParam && ['resumes', 'job-descriptions'].includes(filterParam)){
            setFilter(filterParam);
        } 

        const documentParam = queryParams.get("id");
        const selectedParam = documents.find(item => (item._id === documentParam) && (item.document_type === filterParam));
        if(documentParam && selectedParam){
            handleChangeDocument({}, selectedParam);
        }
    }, [isDocumentsLoading])

    const handleChangeDocument = async (event, newVal) => {
        if(!newVal){
            setSelectedDocument("");
            setRowData([]);
            return;
        }
        setSelectedDocument(newVal);
        navigate(`?filter=${filter}&id=${newVal._id}`, {replace: true})
        if(!newVal) return;

        const data = await getSimilarityScore(newVal?._id);
        setRowData(data.map(item=>{
            const otherDocument = documents.find(innerItem=>innerItem._id === item._id);
            if(!otherDocument){
                return otherDocument
            }
            return {
                _id : otherDocument._id,
                score : item.score,
                status : otherDocument.status,
                filename : otherDocument.filename,
                document_type : otherDocument.document_type,
                target : otherDocument.target,
            }
        }).filter(item=>!!item))    
    }

    const handleFilterChange = (event, newVal)=>{
        setFilter(newVal);
        navigate(`?filter=${newVal}`, {replace: true})
        setSelectedDocument(null);
        setRowData([]);
    }

    const handleStatusUpdate = async (documentId, actionItem) => {
        try {
            await updateDocumentStatus(documentId, actionItem);
            setRowData(prev=>prev.map(doc => 
                doc._id === documentId ? {...doc, status : actionItem} : doc
            ))
            setRowData(prev=>prev.filter(doc => doc.status !== "archived"))
        }catch(error){
            toast.error(error.message, {closeOnClick:true});
        }finally{
            // nothing to do currently
        }
    }

    const columnDefs = [
        { headerName: 'Document', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:3,
            minWidth:250,
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",
            },
            cellClass:"name-cell",
            headerClass:"not-centered-header-cell",
            cellRenderer:FilenameRenderer
        },
        {
            headerName:"Status", flex:3, cellClass:"centered-cell", field:"status", cellRenderer:StatusRenderer,
            cellRendererParams:{
                onUpdate:handleStatusUpdate
            }
        },
        { headerName: 'Score', field:'score', flex:1, sort:"desc", sortable:"true",  cellClass:"centered-cell", cellRenderer:ScoreRenderer },
        { headerName: filter === "resumes" ? "View Job" : 'Preview', flex:1, cellClass:"centered-cell", cellRenderer:PreviewRenderer, 
            cellRendererParams:{
                onPreview:(document)=>{
                    setPreviewDocument(documents.find(item=>item._id === document._id));
                    setOpenPreview(true);
        }}},
        { headerName: 'Analyze', flex:1, colId:"analyze", cellClass:"centered-cell", cellRenderer:AnalyzeRenderer, 
            cellRendererParams:{
                resume : filter === 'resumes' ? selectedDocument : undefined,
                jobDescription : filter !== 'resumes' ? selectedDocument : undefined,
                documents : documents
            }
        }
    ]

    // const getRowStyle = useCallback((params) => {
    //     if (selectedDocument.document_type === "job-descriptions" && params.data.target !== selectedDocument?._id) {
    //       return { backgroundColor: 'rgba(225,254,224, 0.35)' }; 
    //     }
    //     return null;
    // }, [selectedDocument]);

    const handleUpdateDocuments = ()=>{
        syncDocuments();
        setSelectedDocument("");
        setRowData([]);
    }

    return (
        <div className={isMobile ? "home-container" : "rank-and-analyze-container"} style={ isMobile ? {height:"calc(100svh - 4rem - 2.5svh - 1rem - 3rem)"} : null}>
            <DocumentPreview 
                open={openPreview}
                document={previewDocument}
                onClose={()=>{
                    setOpenPreview(false);
                }}
            />
            
            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:"100%",
                    padding:"0rem 1rem",
                    height:"100%",
                    borderRadius:"18px",
                    display:"flex",
                    flexDirection:"column",
                }}
            >
                <div className="rank-and-analyze-header">
                    <RadioGroup
                        row
                        style={{
                            margin:"1rem 0.5rem 0.5rem 1rem"
                        }}
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="job-descriptions"
                        name="radio-buttons-group"
                        id="radio-buttons-group"
                        value={filter}
                        onChange={handleFilterChange}
                    >
                        <FormControlLabel value="job-descriptions"
                        sx={{
                            background:filter === 'job-descriptions' ? "#EA723C" : undefined,
                            borderRadius:"25px",
                            color:filter === 'job-descriptions' ? "white" : undefined,
                            padding:"0.05rem 1.5rem 0.05rem 0.5rem",
                            height : "2.5rem"
                        }}
                        control={<Radio 
                            sx={{
                                '&.Mui-checked': {
                                    color: "white",
                                },
                            }}  
                        />} label="Job Descriptions" />
                        <FormControlLabel value="resumes"
                        sx={{
                            background:filter === 'resumes' ? '#EA723C' : undefined,
                            borderRadius:"25px",
                            color:filter === 'resumes' ? "white" : undefined,
                            padding:"0.05rem 1.5rem 0.05rem 0.5rem",
                            height : "2.5rem"
                        }}
                        control={<Radio 
                        sx={{
                            '&.Mui-checked': {
                                color: "white",
                            },
                        }}
                        />} label="Resumes" />
                        <Fade in={isStale} mountOnEnter unmountOnExit>
                            {
                                isMobile ? 
                                <IconButton
                                    style={{
                                        position:"absolute",
                                        right:"calc(2% + 1rem)"
                                    }}
                                    onClick={handleUpdateDocuments}
                                >
                                    <AutorenewOutlined style={{
                                        color:"#EA723C"
                                    }} />
                                </IconButton>
                                :
                                <Button
                                    variant="contained"
                                    style={{
                                        padding:"0.8rem",
                                        borderRadius:"0.5rem",
                                        font:"inherit",
                                        background:"#EA723C",
                                        margin:"auto 0 auto auto",
                                        height:"2.3rem",
                                        textTransform:"none"
                                    }}
                                    startIcon={<AutorenewOutlined />}
                                    onClick={handleUpdateDocuments}
                                >
                                    Load new documents
                                </Button>
                            }
                        </Fade>
                    </RadioGroup>

                    
                    <CustomAutoComplete
                        disablePortal
                        id="combo-box-demo"
                        options={documents?.filter(x=>x.document_type === filter) || []}
                        getOptionKey={(document)=>document._id}
                        getOptionLabel={(document)=>document ? document.filename : ''}
                        sx={{ 
                            width: "100%", padding : "0rem 0.2rem", boxSizing : "border-box",
                            "& .MuiInputBase-root" : {
                                height : "2.8rem",
                            }
                        }}
                        defaultValue={null}
                        value={selectedDocument || null}
                        placeholder="Select Document"
                        onChange={handleChangeDocument}
                        />

                </div>

                <CustomGrid 
                    gridId={"rank-and-analyze-grid"}
                    rowData={rowData}    
                    columnDefs={columnDefs}
                    containerStyle={{ mobile:{
                        width: 'calc(2rem + 100%)', height:"100%", marginLeft:"-1rem", marginTop:"0rem"
                    }}}
                    // getRowStyle={getRowStyle}
                />
            </Paper>
        </div>
    )
}

export default RankAndAnalyze;