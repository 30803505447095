import { authAPI } from "./axiosIstances";

export const login = async (credentials) => {
    const response = await authAPI.post("/login", credentials);
    return response.data;
}

export const logout = async () => {
    await authAPI.get("/logout");
}

export const getUser = async () => {
    const response = await authAPI.get("/user");
    return response.data;
}

export const requestResetPassword = async (data) => {
    const response = await authAPI.post("/request-reset-password", data);
    return response.data;
}

export const validateResetPasswordToken = async (data) => {
    const response = await authAPI.post("/validate-reset-password-token", data);
    return response.data;
}

export const resetPassword = async (data) => {
    const response = await authAPI.post("/reset-password", data);
    return response.data;
}