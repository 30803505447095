import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./reset-password-page.css";
import { CustomTextField } from "@components/CustomMUIInputs";
import { Button } from "@mui/material";
import { getFormJson } from "@utils/formUtils";
import { validatePassword } from "@utils/validator";
import { resetPassword, validateResetPasswordToken } from "@services/authService";
import { toast } from "react-toastify";
import { LoadingIndicator } from "@components/index";
import { ErrorPage } from "..";
import { pageErrors } from "@constants/errorPageInfo";

const ResetPasswordPage = ()=>{
    const [params, setParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);
    const [errors, setErrors] = useState({});
    const [done, setDone] = useState(false);

    const handlePasswordReset = async (event) => {
        event.preventDefault();

        const formJson = getFormJson(event);

        const {isValid, message} = validatePassword(formJson.password)
        if(!isValid){
            return setErrors({password : message})
        }
        if(formJson.password !== formJson.confirm_password){
            return setErrors({confirm_password : "Password do not match!"})
        }

        try{
            await resetPassword({
                token : token,
                password : formJson.password
            });
            toast.success("Password reset successfully");
            setDone(true);
        }catch(error){
            toast.error(error.message);
        }
    }

    useEffect(()=>{
        const initPage = async ()=>{
            setToken(params.get("token"));
            try{
                await validateResetPasswordToken({"token" : params.get("token")});
            }catch(error){
                setErrors({page : error.message})
            }finally{
                setLoading(false);
                setParams("");
            }
        }

        initPage();
    }, [])

    return (
        loading ? <LoadingIndicator /> : 
        (errors.page || !token) ? <ErrorPage error={pageErrors.INVALID_OR_EXPIRED_TOKEN} /> : 
        <div className="reset-password-page-container">
            <div className="reset-password-form">
                <header>{"Reset Password"}</header>
                {
                    done ? 
                    <div className="done">
                        <div>Password has been reset</div>
                        <Link
                            to={"/auth/login"}
                            style={{
                                marginTop : "1rem",
                                fontFamily : "inherit",
                                textTransform : "none",
                                textDecoration : "none",
                                color : "#EA723C"
                            }}
                        >
                            Go Back to Login
                        </Link>
                    </div> : 
                    <form onSubmit={handlePasswordReset}>
                        <CustomTextField 
                            id={"password"}
                            name={"password"}
                            placeholder="Enter New Password"
                            label={"New Password"}
                            error={!!errors.password}
                            helperText={errors.password}
                            required
                        />
                        <CustomTextField 
                            id={"confirmPassword"}
                            name={"confirm_password"}
                            placeholder="Re-enter New Password"
                            label={"Confirn New Password"}
                            error={!!errors.confirm_password}
                            helperText={errors.confirm_password}
                            required
                        />

                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                background : "#EA723C",
                                width: "100%",
                                marginTop : "1rem",
                                fontFamily : "inherit",
                                textTransform : "none"
                            }}
                        >
                            Reset Password
                        </Button>
                    </form>
                }

            </div>
        </div>
    )
}

export default ResetPasswordPage;