import { useDocuments } from "@contexts/DocumentsContext";
import useResponsiveView from "@hooks/useResponsiveView";
import { Link as MUILink, Breadcrumbs, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const CustomBreadcrumbs = () => {
    const location = useLocation();
    const isMobile = useResponsiveView();
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const { documents, isLoading } = useDocuments("job-descriptions");

    const pathMap = useMemo(()=>({
        "users" : "Users",
        "resumes" : "Resumes",
        "job-descriptions" : "Job Descriptions",
        "rank-and-analyze" : "Rank & Analyze",
        "help-and-support" : "Help & Support",
        "archive" : "Archive",
    }), [])

    useEffect(()=>{
        if(isLoading) return;
        const pathSegments = location.pathname.split("/").filter(Boolean);
        const generatedBreadcrumbs = [
            {title: "Dashboard", to: "/"}
        ]
        if(!pathSegments.length){
            generatedBreadcrumbs.push({
                title: "Home",
            })
        }
        for(let i=0; i < pathSegments.length; i++){
            const segment = pathSegments[i];
            if(pathMap[segment]){
                generatedBreadcrumbs.push({
                    title: pathMap[segment],
                    to : segment,
                })
            }else if(pathSegments.length > 1 && pathSegments[i - 1] === "job-descriptions"){
                generatedBreadcrumbs.push({
                    title:documents?.find(item => item._id === segment)?.filename,
                    to: segment
                })
            }else{
                generatedBreadcrumbs.push({
                    title:segment,
                    to: segment
                })
            }
        }
        setBreadcrumbs(generatedBreadcrumbs);
    }, [location.pathname, isLoading])

    return (
        <Breadcrumbs
            aria-label="breadcrumb"
            style={{
                width: "inherit",
                position:"relative",
                // padding: isMobile ? "0rem 1rem" : "1.25rem 0rem 0rem calc(2% + 2rem)",
                height:"1.5rem",        
                display:"flex",
                alignItems:"center",  
                fontSize:"1rem",
                color:"black"                
            }}  
            separator="•"
        >
            {
                breadcrumbs.map(((item, index)=>(
                    index === breadcrumbs.length - 1 ?
                    <Typography key={index} style={{fontSize:"1rem"}}  color="#13131380">
                        {item.title}
                    </Typography>
                    :
                    <MUILink key={index} component={Link} style={{fontSize:"1rem"}} underline="none" color="#000" to={item.to}>
                        {item.title}
                    </MUILink>
                )))
            }
        </Breadcrumbs>
        
    )
}

export default CustomBreadcrumbs;