import { CustomTextField } from "@components/CustomMUIInputs";
import { CustomGrid, DocumentPreview } from "@components/index";
import { useDocuments } from "@contexts/DocumentsContext";
import useResponsiveView from "@hooks/useResponsiveView";
import { FilterIcon } from "@icons/DialogsIcons";
import Lottie from "react-lottie";
import deleteAnimation from "@animations/deleteAnimation.json";
import { AutorenewOutlined, SearchOutlined } from "@mui/icons-material";
import { Button, Chip, CircularProgress, Fade, FormControlLabel, IconButton, Paper, Radio, RadioGroup } from "@mui/material";
import { ActionsRenderer, FilenameRenderer, PreviewRenderer } from "@renderers/documents-renderers";
import { deleteDocument, fetchArchivedDocuments, fetchDocuments } from "@services/documentsService";
import { useQuery } from "@tanstack/react-query";
import { documentsDialogTypes as dDialogTypes } from "@constants/dialogsEnums";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./archive.css";
import ConfirmDialog from "@components/ConfirmDialog";
import { toast } from "react-toastify";
import useResourceMutation from "@hooks/useResourceMutation";
import { useAuth } from "@contexts/AuthContext";
import { LoadingButton } from "@mui/lab";
import { DeleteIcon } from "@icons/ActionsIcons";

// make this a separate component
const ResponsiveWrapper = ({children}) => {
    const isMobile = useResponsiveView();

    return (
        isMobile ?
        <>{children}</>
        :
        <Paper
            elevation={1}
            sx={{
                width:"93%",
                marginTop:"0.5rem",
                padding:"0rem 1rem",
                height:"100%",
                borderRadius:"32px",
                display:"flex",
                flexDirection: "column"
            }}
        >
            {children}
        </Paper>
    )
}

const Archive = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useResponsiveView();
    const { user } = useAuth();

    const queryKeys = useMemo(()=>['archivedDocuments'], []);
    const { data:archivedDocuments=[], isLoading } = useQuery({
        queryKey : queryKeys,
        queryFn : fetchArchivedDocuments,
    })
    const { 
        mutateAsync:mutateDeleteDocument, 
    } = useResourceMutation(queryKeys, 'delete', deleteDocument);


    const [selectedDocument, setSelectedDocument] = useState(null);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [deletingMultiple, setDeletingMultiple] = useState(false);
    const [openDialog, setOpenDialog] = useState(dDialogTypes.NONE);

    const [filter, setFilter] = useState(()=>{
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get("filter");
        if(filterParam && ['resumes', 'job-descriptions'].includes(filterParam)){
            return filterParam;
        }
        return 'job-descriptions'
    });
    const [searchFilterText, setSearchFilterText] = useState("");

    const isExternalFilterPresent = useCallback(()=>{
        return searchFilterText !== "" || filter !== null;
    }, [filter, searchFilterText]);
    
    const doesExternalFilterPass = useCallback((node)=>{
        return (
            (searchFilterText === "" || node.data?.filename?.toLowerCase().includes(searchFilterText?.toLowerCase()))
            &&
            (filter === null || node.data?.document_type === filter)
        );
    }, [filter, searchFilterText]);

    const [columnDefs, setColumnsDefs] = useState([
        { headerName: 'Name', 
            sortable: true, 
            filter: true, 
            field:"filename",
            flex:5,
            minWidth : isMobile ? 350 : undefined,
            headerClass:"not-centered-header-cell",
            cellStyle : {
                fontSize:isMobile ? "1rem": "1.1rem",
                textAlign : "left",

            },
            cellRenderer: memo(FilenameRenderer),
            cellClass:"name-cell",
            checkboxSelection:true, headerCheckboxSelection:true, headerCheckboxSelectionFilteredOnly:true,
        },
        { headerName: 'Preview', flex:1, cellClass:"centered-cell", cellRenderer: memo(PreviewRenderer), 
            cellRendererParams:{
                onPreview:(document)=>{
                    setSelectedDocument(document);
                    setOpenDialog(dDialogTypes.PREVIEW_DOCUMENT);
        }}},
        { headerName: 'Actions', flex:2, cellClass:"centered-cell", cellRenderer: memo(ActionsRenderer), 
            cellRendererParams:{
                actionList: ["delete"],
                onAction:(document)=>{
                    setSelectedDocument(document);
                    setOpenDialog(dDialogTypes.DELETE_DOCUMENT);
                },
                disabled:!user?.access_delete,
        }},
        {
            headerName: 'Created At',
            field: 'created_at',
            sortable: true,
            hide: true,  // don't need to show the column - needs for sorting only
            sort: 'desc',
        },
    ])

    const handleDeleteDocuments = async (event)=>{
        try{
            if(openDialog === dDialogTypes.DELETE_DOCUMENT){
                await mutateDeleteDocument({data:selectedDocument?._id});
                toast.success("File deleted successfully", {closeOnClick:true})
                setOpenDialog(dDialogTypes.NONE);
            }else{
                // multi
                setOpenDialog(dDialogTypes.NONE);
                setDeletingMultiple(true);
                await mutateDeleteDocument({data:selectedDocuments?.map(item=>item._id), isBatch:true});
                setDeletingMultiple(false);
                toast.success(selectedDocuments.length + " file(s) deleted successfully", {closeOnClick:true});
            }
        }catch(error){
            toast.error(error.message, {closeOnClick:true})
        }finally {
            setOpenDialog(dDialogTypes.NONE);
        }
    }

    const handleRowSelected = useCallback((event)=>{
        setSelectedDocuments(event.api.getSelectedRows());
    }, []);

    useEffect(()=>{
        if(isLoading) return;
        const queryParams = new URLSearchParams(location.search);
        const filterParam = queryParams.get("filter");
        if(filterParam && ['resumes', 'job-descriptions'].includes(filterParam)){
            setFilter(filterParam);
        } 
    }, [isLoading])

    const handleFilterChange = (event, newVal)=>{
        setFilter(newVal);
        navigate(`?filter=${newVal}`, {replace: true})
    }
    
    return (
        <div className="archive-container">
            <ConfirmDialog
                open={openDialog === dDialogTypes.DELETE_DOCUMENT} title="Remove Document"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete the document ${selectedDocument?.filename}?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={handleDeleteDocuments}
            />

            <ConfirmDialog
                open={openDialog === dDialogTypes.DELETE_MULTI} title="Remove Document(s)"
                icon={
                    <Lottie
                        options={{
                            loop : true,
                            autoplay : true,
                            animationData : deleteAnimation,
                            renderer : "svg"
                        }}
                        height={"10rem"}
                        width={"50%"}
                    />
                }
                content={`Are you sure you want to delete selected ${selectedDocuments?.length} documents?`}
                onClose={()=>{setOpenDialog(dDialogTypes.NONE)}}
                onConfirm={handleDeleteDocuments}
            />

            <DocumentPreview
                open={openDialog === dDialogTypes.PREVIEW_DOCUMENT}
                document={selectedDocument}
                onClose={()=>setOpenDialog(dDialogTypes.NONE)}
            />

            <Paper
                elevation={isMobile ? 0 : 1}
                sx={{
                    width:"100%",
                    padding:"0rem 1rem",
                    height:"100%",
                    borderRadius:"20px",
                    display:"flex",
                    flexDirection:"column",
                }}
            >
                <div className="archive-header">
                    <RadioGroup
                        row={!isMobile}
                        style={{
                            margin:"1rem 0.5rem 0.5rem 1rem"
                        }}
                        aria-labelledby="archive-radio-buttons-group-label"
                        defaultValue="job-descriptions"
                        name="archive-radio-buttons-group"
                        id="archive-radio-buttons-group"
                        value={filter}
                        onChange={handleFilterChange}
                    >
                        <FormControlLabel value="job-descriptions"
                        sx={{
                            background:filter === 'job-descriptions' ? "#EA723C" : undefined,
                            borderRadius:"25px",
                            color:filter === 'job-descriptions' ? "white" : undefined,
                            padding:"0.05rem 1.5rem 0.05rem 0.5rem",
                            height : "2.5rem"
                        }}
                        control={<Radio 
                            sx={{
                                '&.Mui-checked': {
                                    color: "white",
                                },
                            }}  
                        />} label="Job Descriptions" />
                        <FormControlLabel value="resumes"
                        sx={{
                            background:filter === 'resumes' ? '#EA723C' : undefined,
                            borderRadius:"25px",
                            color:filter === 'resumes' ? "white" : undefined,
                            padding:"0.05rem 1.5rem 0.05rem 0.5rem",
                            height : "2.5rem"
                        }}
                        control={<Radio 
                        sx={{
                            '&.Mui-checked': {
                                color: "white",
                            },
                        }}
                        />} label="Resumes" />
                    </RadioGroup>
                    {
                        selectedDocuments.length ?
                        <LoadingButton
                            variant="contained" 
                            style={{
                                background:user?.access_delete ?"#FF4848" : "lightgray",
                                border:"1px solid " + (user?.access_delete ? "#FF4848" : "lightgray"),
                                textTransform:"none",
                                borderRadius:"0.5rem",
                                fontFamily:"Montserrat",
                                padding:"0.3rem",
                                height:"2.5rem",
                                margin:"0rem 0.5rem",
                            }}
                            type="input"
                            inputMode="file"
                            onClick={()=>{
                                setOpenDialog(dDialogTypes.DELETE_MULTI);
                            }}
                            loadingIndicator={
                                <CircularProgress color="inherit" sx={{color:"white"}} size={16} />
                            }
                            loading={deletingMultiple}
                            disabled={!user?.access_delete}
                        >
                            <DeleteIcon color="white" />
                        </LoadingButton>
                        : null
                    }
                </div>

                <div className="archive-search-bar">
                    <CustomTextField
                        sx={{
                            height:"2.8rem"
                        }}
                        placeholder={"Search..."}
                        EndIcon={SearchOutlined}
                        defaultValue={""}
                        onChange={(event)=>setSearchFilterText(event.target.value)}
                    />
                    <IconButton
                        style={{
                            marginLeft:"0.5rem"
                        }}
                        disabled={true}
                    >
                        <FilterIcon color="gray" height="2.5rem" width="2.5rem" />
                    </IconButton>
                </div>

                <CustomGrid 
                    gridId={"ArchiveTable"}
                    rowData={isLoading ? null : archivedDocuments}
                    columnDefs={columnDefs}
                    rowSelection='multiple'
                    suppressRowClickSelection
                    onRowSelected={handleRowSelected}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                >
                </CustomGrid>
            </Paper>
        </div>
    )
}

export default Archive;